import React, { Component, useRef, useContext } from 'react';
import {
  DataTable,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableHeader,
  TableBody,
  TableCell,
} from 'carbon-components-react';
import Printer32 from '@carbon/icons-react/lib/printer/32';
import { Button } from 'carbon-components-react';
import Information20 from '@carbon/icons-react/lib/information/32';

import Select, { components } from 'react-select';
import DashboardContext from '../../context/dashboard/dashboardContext';

const sizeOptions = [
  { value: 'Big', label: 'Big', seg: 'seg_size' },
  { value: 'Medium', label: 'Medium', seg: 'seg_size' },
  { value: 'Small', label: 'Small', seg: 'seg_size' },
];
const typeOptions = [
  { value: 'Business', label: 'Business', seg: 'seg_type' },
  { value: 'Leisure', label: 'Leisure', seg: 'seg_type' },
  { value: 'Mixed', label: 'Mixed', seg: 'seg_type' },
  { value: 'Group', label: 'Group', seg: 'seg_type' },
];

const geoOptions = [
  { value: 'South East Coast', label: 'South East Coast', seg: 'seg_geo' },
  { value: 'North East', label: 'North East', seg: 'seg_geo' },
  { value: 'Midwest', label: 'Midwest', seg: 'seg_geo' },
  { value: 'South East Inland', label: 'South East Inland', seg: 'seg_geo' },
  { value: 'South West', label: 'South West', seg: 'seg_geo' },
  { value: 'West South', label: 'West South', seg: 'seg_geo' },
  { value: 'West North', label: 'West North', seg: 'seg_geo' },
];

const groupedOptions = [
  {
    label: 'Size',
    options: sizeOptions,
  },
  {
    label: 'Type',
    options: typeOptions,
  },
  {
    label: 'Geographic Location',
    options: geoOptions,
  },
];

const groupStyles = {
  borderRadius: '5px',
};

const Group = (props) => (
  <div style={groupStyles}>
    <components.Group {...props} />
  </div>
);

function compare(cell1, cell2, locale) {
  if (typeof cell1 == 'string' && cell1.includes('%')) {
    // revpar growth
    return parseFloat(cell1) < parseFloat(cell2) ? -1 : 1;
  } else if (typeof cell1 == 'number') {
    // rank
    return cell1 < cell2 ? -1 : 1;
  } else if (typeof cell1 == 'string') {
    // confidence
    return cell1.localeCompare(cell2);
  } else if (cell1.props.children instanceof Array) {
    // top variables
    return cell1.props.children[0].props.children[1].props.children.localeCompare(
      cell2.props.children[0].props.children[1].props.children
    );
  } else if (
    typeof cell1.props.children == 'string' &&
    typeof cell2.props.children == 'string'
  ) {
    // market
    return cell1.props.children.localeCompare(cell2.props.children);
  } else if (cell1.type.name === 'u') {
    // number bar
    // console.log(cell1.props.number);
    // console.log(cell2.props.number);
    return cell1.props.number < cell2.props.number ? -1 : 1;
  }
}
class ComponentToPrint extends Component {
  render() {
    return (
      <Table>
        <colgroup>
          <col />
          <col style={{ width: '130px' }} />
          <col />
          <col />
          <col style={{ width: '700px' }} />
        </colgroup>
        <TableHead>
          <TableRow>
            {this.props.headers.map((header) => (
              <TableHeader
                className="datatable-header"
                {...this.props.getHeaderProps({ header })}
              >
                {header.header}
              </TableHeader>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {this.props.rows.slice(0, this.props.currentPageSize).map((row) => (
            <TableRow key={row.id}>
              {row.cells.map((cell) => (
                <TableCell key={cell.id}>{cell.value}</TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    );
  }
}
const LandingTable = ({ currentPageSize, rows, headers }) => {
  const dashboardContext = useContext(DashboardContext);

  const { getDatatable, tableData } = dashboardContext;
  //
  const customSortRow = (
    cellA,
    cellB,
    { sortDirection, sortStates, locale }
  ) => {
    if (sortDirection === sortStates.DESC) {
      return compare(cellB, cellA, locale);
    }

    return compare(cellA, cellB, locale);
  };
  const handleOnChange = (value) => {
    getDatatable(value);
    // console.log(value);
  };
  const componentRef = useRef();

  return (
    <DataTable
      rows={rows}
      headers={headers}
      sortRow={customSortRow}
      isSortable
      useZebraStyles={false}
      size={null}
      render={({ rows, headers, getHeaderProps }) => (
        <TableContainer title="All Market Ranking for 2024-2028">
          <div className="filter">
            <Button
              style={{
                zIndex: 99,
                float: 'left',
                marginTop: '5px',
                marginRight: '10px',
              }}
              kind="ghost"
              size="small"
              hasIconOnly
              renderIcon={Printer32}
              tooltipAlignment="center"
              tooltipPosition="bottom"
              iconDescription="Print the table"
              onClick={() => {
                // console.log(tableData);
                // console.log(JSON.stringify(tableData));
                const element = document.createElement('a');
                const file = new Blob([JSON.stringify(tableData)], {
                  type: 'text/plain',
                });
                element.href = URL.createObjectURL(file);
                element.download = 'data-table.json';
                document.body.appendChild(element); // Required for this to work in FireFox
                element.click();
              }}
            />
            {/* <CSVLink
              // id="print"
              // style={{
              //   zIndex: 99,
              //   float: 'left',
              //   marginTop: '5px',
              //   marginRight: '10px',
              // }}
              data={rows}
              headers={csv_headers}
            >
              Download the data table
            </CSVLink> */}
            {/* <Tooltip direction="bottom" tabIndex={0} triggerText="">
              <p>{tooltip['tooltip']}</p>
            </Tooltip> */}
            {/* <ReactToPrint
              id="print"
              trigger={() => (
                <Button
                  style={{
                    zIndex: 99,
                    float: 'left',
                    marginTop: '5px',
                    marginRight: '10px',
                  }}
                  kind="ghost"
                  size="small"
                  hasIconOnly
                  renderIcon={Printer32}
                  tooltipAlignment="center"
                  tooltipPosition="bottom"
                  iconDescription="Print the table"
                />
              )}
              content={() => componentRef.current}
            /> */}
            <Select
              style={{ paddingLeft: '200px' }}
              id="select"
              classNamePrefix="react-select"
              maxMenuHeight={500}
              isMulti
              closeMenuOnSelect={false}
              // defaultValue={colourOptions[1]}
              options={groupedOptions}
              components={{ Group }}
              onChange={handleOnChange}
              menuPortalTarget={document.querySelector('body')}
              placeholder={'Filter by segmentation...'}
            />
          </div>

          <ComponentToPrint
            ref={componentRef}
            rows={rows}
            headers={headers}
            currentPageSize={currentPageSize}
            getHeaderProps={getHeaderProps}
          />
        </TableContainer>
      )}
    />
  );
};

export default LandingTable;
